import { API, Auth } from 'aws-amplify';
import { API_NAME, CURRENT_PATH, HISTORIC_PATH } from '../../config';

export const fetchHistoricMetrics = async () => {

    const params = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }
    return await API.get(API_NAME, HISTORIC_PATH, params)
}

export const fetchCurrentMetrics = async () => {
    const params = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }
    return await API.get(API_NAME, CURRENT_PATH, params)
}