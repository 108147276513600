import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DashboardActions } from '../../redux/dashboard';
import Dashboard from './Dashboard';

const DashboardContainer = (props) => {
    return (
        <Dashboard {...props} />
    )
}

const mapStateToProps = (state) => {
    return {
        pollInterval: state.dashboard.pollInterval,
        data: state.dashboard.data,
        isResizable: state.dashboard.isResizable,
        isDraggable: state.dashboard.isDraggable,
        selectedQueues: state.dashboard.selectedQueues
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setPollInterval: DashboardActions.setPollInterval,
        setServiceLevel: DashboardActions.setServiceLevel,
        setTimeRange: DashboardActions.setTimeRange,
        startPolling: DashboardActions.startPolling,
        stopPolling: DashboardActions.stopPolling,
        toggleDrag: DashboardActions.toggleDrag,
        toggleResize: DashboardActions.toggleResize,
        setSelectedQueues: DashboardActions.setSelectedQueues
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)