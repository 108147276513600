import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Style from './LayoutCard.style';

const LayoutCard = (props) => {
    const { classes, title, children } = props;

    return (
        <Paper className={classes.root}>
            {title &&
                <Typography align='center' variant='h5'>{title}</Typography>
            }
            {children}
        </Paper>
    )
}
export default withStyles(Style)(LayoutCard);