const Style = () => ({
    card: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
    }
})
export default Style;