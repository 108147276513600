const Styles = (theme) => ({
    root: {
        marginTop: theme.spacing(8)
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    avatar: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing()
    }
})
export default Styles