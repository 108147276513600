import React from 'react';
import { Auth, Hub } from 'aws-amplify';

//custom components
import Dashboard from '../dashboard/DashboardContainer';
import SignIn from '../signin/SignIn';

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const logout = () => {
    Auth.signOut()
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setIsAuthenticated(true);
        break;
      case 'signIn_failure':
        setIsAuthenticated(false);
        break;
      default:
        break;
    }
  });

  React.useEffect(() => {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      setIsAuthenticated(true);
    }).catch(e => {
      console.log('Error: ' + e);
      setIsAuthenticated(false);
    });
  }, [])

  return (
    <>
      {isAuthenticated ? (
        <>
          <Dashboard logout={logout} />
        </>
      ) : (
          <SignIn isAuthenticated={isAuthenticated} />
        )
      }
    </>
  )
}
export default App;