/*
* This combines all the Sagas into one Saga
*/

import { all, fork } from 'redux-saga/effects';

// All Sagas
import * as SagasApps from './sagas.combine';

// Combines all Sagas
export default function* MainSaga() {
    yield all(Object.keys(SagasApps).map(Feature => Object.keys(SagasApps[Feature]).map(saga => fork(SagasApps[Feature][saga]))).flat())
}