// Api endpoint
export const AMPLIFY_CONFIG = {
    API: {
        endpoints: [
            {
                name: "metrics",
                // API endpoint
                endpoint: "https://rxakuz1j6l.execute-api.ap-southeast-2.amazonaws.com/prod/metrics"
            }
        ]
    },
    Auth: {
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_edNUyoI9O',
        userPoolWebClientId: '3q04hc5aqik3qm5bkus6r6qo1v',
        oauth: {
            domain: 'boc-uat.auth.ap-southeast-2.amazoncognito.com',
            scope: ['openid'],
            redirectSignIn: 'https://wallboard.ccp-uat.bocaws.com.au/',
            redirectSignOut: 'https://wallboard.ccp-uat.bocaws.com.au/',
            responseType: 'code',
        }
    }
}

// api path for current and historic metrics
export const API_NAME = 'metrics';
export const CURRENT_PATH = '/current';
export const HISTORIC_PATH = '/historic';

// Refresh rates in seconds
export const REFRESH_RATES = [5, 10, 15];

// Colors - Good -> Warning -> Critical
export const COLORS = ["#008000", "#FFBB28", "#FF0000"]

// Default queue
export const DEFAULT_QUEUE = "au"

// Background and text color for metric cards
export const CARD_BACKGROUND_COLOR = "#4615b2"
export const CARD_TEXT_COLOR = "#ffffff"
