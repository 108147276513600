//import actions
import * as Types from "./types"

//Initial state
const initialState = {
    pollInterval: 60,
    data: {},
    isResizable: false,
    isDraggable: false,
    selectedQueues: []
}

const Reducer = (state = initialState, action) => {

    switch (action.type) {
        case Types.SET_POLL_INTERVAL:
            return {
                ...state,
                pollInterval: action.payload
            }
        case Types.SET_SERVICE_LEVEL:
            return {
                ...state,
                serviceLevel: action.payload
            }
        case Types.SET_TIME_RANGE:
            return {
                ...state,
                timeRange: action.payload
            }
        case Types.UPDATE_DATA:
            return {
                ...state,
                data: action.payload
            }
        case Types.TOGGLE_DRAG:
            return {
                ...state,
                isDraggable: !state.isDraggable
            }
        case Types.TOGGLE_RESIZE:
            return {
                ...state,
                isResizable: !state.isResizable
            }
        case Types.SET_SELECTED_QUEUES:
            return {
                ...state,
                selectedQueues: action.payload
            }
        default:
            return state
    }
}
export default Reducer