import React from 'react';
import { PieChart, Pie, Label, Legend, Cell, ResponsiveContainer } from 'recharts';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Style from './DonutChart.style';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index
}) => {
    if (value < 10) return value;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {value}
        </text>
    );
};

const DonutChart = props => {
    const {
        classes,
        data,
        COLORS,
        midLabel,
        midValue,
        renderLabel = true,
        responsiveContainerProps = { height: '100%', width: '100%' }
    } = props;
    return (
        <div className={classes.root}>
            <Typography variant="h5">
                {props.title}
            </Typography>

            <ResponsiveContainer
                {...responsiveContainerProps}
            >
                <PieChart className={classes.container}>
                    <Pie
                        data={data}
                        cy={130}
                        innerRadius={50}
                        outerRadius={100}
                        labelLine={false}
                        label={renderLabel ? renderCustomizedLabel : renderLabel}
                        dataKey="value"
                    >
                        <Label position="center">{`${midLabel} ${midValue}`}</Label>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Legend layout="horizontal" align="center" verticalAlign="bottom" />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
export default withStyles(Style)(DonutChart);
