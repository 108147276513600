import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Styles from './Signin.style';

const SignIn = (props) => {
    const { classes } = props;

    const handleClick = () => {
        Auth.federatedSignIn()
    }

    React.useEffect(() => {
        document.title = "Sign in"
    }, [])

    return (
        <>
            {props.isAuthenticated ? (
                <Redirect
                    to={{
                        pathname: "/"
                    }}
                />
            ) : (
                    <Container component="main" maxWidth="xs" className={classes.root} >
                        <Paper className={classes.container}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography variant='h6'>Please sign in to continue</Typography>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={() => handleClick()}
                            >
                                Sign In
                        </Button>
                        </Paper>
                    </Container >
                )
            }
        </>
    );
}
export default withStyles(Styles)(SignIn);
