/*
 * This is the main reducers that combines all the sub reducers
 */

// Redux Libaraies
import storage from 'redux-persist/lib/storage'
import { persistCombineReducers } from 'redux-persist'

// Reducers
import DashboardReducer from "./dashboard";

const persistConfig = {
    key: 'wallboard',
    storage
}

/*
 * Function to combine all the sub reducers
 */
const MainReducer = persistCombineReducers(persistConfig, {
    dashboard: DashboardReducer,
});

export default MainReducer;
