const Style = (theme) => ({
    root: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        fontWeight: 700
    }
})
export default Style;