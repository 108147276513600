import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Typography from '@material-ui/core/Typography';

import { v4 as uuidv4 } from 'uuid';

const BarChartWrapper = props => {
  const { data, barKeys, dataKey = 'name', layout = 'horizontal', title, responsiveContainerProps = { height: '100%', width: '100%' } } = props;

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer
        {...responsiveContainerProps}
      >
        <BarChart data={data} layout={layout}>
          {/* <CartesianGrid strokeDasharray="3 3"/> */}
          {
            layout === 'vertical' ?
              [
                <XAxis key={uuidv4()} type='number' />,
                <YAxis key={uuidv4()} dataKey={dataKey} type='category' width={90} />
              ] :
              [
                <XAxis key={uuidv4()} dataKey={dataKey} />,
                <YAxis key={uuidv4()} />
              ]
          }
          <Tooltip />
          <Legend />
          {// Create bars from barKeys prop
            barKeys.map((entry, index) => (
              <Bar key={`${index}`} {...entry} />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BarChartWrapper;
