import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { blue, indigo } from '@material-ui/core/colors';

let Theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: indigo,
    }
});

Theme = responsiveFontSizes(Theme)

export default Theme
