import React from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Dropdown from './_components/dropdown/Dropdown';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


//custom components
import { Loader } from './_components/loader/Loader'
import Style from './Dashboard.style';
import LiveBoard from './_components/live_board/LiveBoard';
import { REFRESH_RATES } from '../../config';

const Dashboard = props => {

  const { classes, pollInterval, startPolling, stopPolling, data } = props;

  const [queueNames, setQueueNames] = React.useState([])

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    document.title = "Wallboard"
  }, [])

  React.useEffect(() => {
    if ('currentData' in data) {
      let list = []
      let queue
      for (queue of data.currentData.queues) {
        list.push(queue.name)
      }
      setQueueNames(list.sort())
    }
  }, [data])

  React.useEffect(() => {
    startPolling({ pollInterval: pollInterval })
    return () => {
      stopPolling()
    }
  }, [pollInterval, startPolling, stopPolling])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={event => handleClick(event)} className={classes.settingsButton} aria-label="settings">
        <SettingsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.menuBar}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  value="resizeToggle"
                  color="primary"
                  checked={props.isResizable}
                  onChange={props.toggleResize}
                />
              }
              label="Resize"
            />
            <Divider className={classes.divider} />
            <FormControlLabel
              control={
                <Switch
                  value="dragToggle"
                  color="primary"
                  checked={props.isDraggable}
                  onChange={props.toggleDrag}
                />
              }
              label="Drag"
            />
          </FormGroup>
          <Divider className={classes.divider} />
          <Dropdown
            onChange={props.setPollInterval}
            value={pollInterval}
            list={REFRESH_RATES}
            id="refresh-delay"
            label="Refresh (secs)"
          />
          <Divider className={classes.divider} />
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink htmlFor="queuesDropdown">Queues</InputLabel>
            <Select
              multiple
              value={[...props.selectedQueues]}
              input={<Input id="queuesDropdown" className={classes.bootstrapInput} />}
              onChange={e => props.setSelectedQueues(e.target.value)}
              renderValue={selected => selected.join(', ')}

            >
              {queueNames.length > 0 &&
                queueNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={props.selectedQueues.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Divider className={classes.divider} />
          <Button
            className={classes.bootstrapButton}
            variant="contained"
            color="primary"
            disableRipple
            fullWidth
            onClick={() => props.logout()}
          >
            Logout
          </Button>
        </div>
      </Popover>
      <div className={classes.container}>
        {data.currentData && data.historicData ? (
          <LiveBoard isResizable={props.isResizable} isDraggable={props.isDraggable} currentData={data.currentData} historicData={data.historicData} selectedQueues={props.selectedQueues} />
        ) : (
            <Loader fill='#f5f5f5' />
          )}
      </div>
    </>
  );
};
export default withStyles(Style)(Dashboard);
