import * as Types from './types';

export const setPollInterval = (pollInterval) => ({
    type: Types.SET_POLL_INTERVAL,
    payload: pollInterval
})

export const setServiceLevel = (serviceLevel) => ({
    type: Types.SET_SERVICE_LEVEL,
    payload: serviceLevel
})

export const setTimeRange = (timeRange) => ({
    type: Types.SET_TIME_RANGE,
    payload: timeRange
})

export const setSelectedQueues = (queues) => ({
    type: Types.SET_SELECTED_QUEUES,
    payload: queues
})

export const startPolling = (payload) => ({
    type: Types.START_POLLING,
    payload: payload
})

export const stopPolling = () => ({
    type: Types.STOP_POLLING
})

export const updateData = (payload) => ({
    type: Types.UPDATE_DATA,
    payload: payload
})

export const toggleDrag = () => ({
    type: Types.TOGGLE_DRAG,
})

export const toggleResize = () => ({
    type: Types.TOGGLE_RESIZE,
})