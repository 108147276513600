import React from 'react';
import Typography from '@material-ui/core/Typography';

import Style from './MetricCard.style';
import withStyles from '@material-ui/core/styles/withStyles';

const MetricCard = (props) => {
    const { classes, background, text } = props;
    return (
        <div className={classes.card} style={{ background: background !== undefined ? background : 'white', color: text !== undefined ? text : 'black' }}>
            <Typography gutterBottom align='center' variant='h2'>{props.title}</Typography>
            <Typography align='center' variant='h1'>{props.value}</Typography>
        </div>
    )
}
export default withStyles(Style)(MetricCard);