//import actions
import * as actionTypes from './types';
import { fetchCurrentMetrics, fetchHistoricMetrics } from './operations';

// Redux Saga
import { put, call, take, race, all } from "redux-saga/effects";

export function* pollRequestWatcherSaga() {
    while (true) {
        const { payload } = yield take(actionTypes.START_POLLING);
        yield race([
            call(pollAPI, payload),
            take(actionTypes.STOP_POLLING)
        ])
    }
}

function* pollAPI(payload) {
    while (true) {
        let data = {}

        const { historicData, currentData } = yield all({
            historicData: call(fetchHistoricMetrics),
            currentData: call(fetchCurrentMetrics)
        })

        if (historicData) data.historicData = historicData;
        if (currentData) data.currentData = currentData;

        yield put({ type: actionTypes.UPDATE_DATA, payload: data })
        yield call(delay, payload.pollInterval * 1000)
    }
}

function delay(duration) {

    const promise = new Promise(resolve => {
        setTimeout(() => resolve(true), duration)
    })
    return promise
}