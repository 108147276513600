//React component
import React from 'react';

//Material library
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/core/styles/withStyles';

import Style from './Dropdown.style';

const Dropdown = (props) => {
    const { classes } = props
    return (
        <FormControl fullWidth>
            <InputLabel shrink htmlFor={props.id}>{props.label}</InputLabel>
            <Select
                value={props.value}
                onChange={(e) => { props.onChange(e.target.value) }}
                input={<InputBase className={classes.bootstrapInput} id={props.id} />} >
                {/* //dynamically create Select fields using map function and list passed as props.   */}
                {props.list.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default withStyles(Style)(Dropdown);