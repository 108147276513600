// Redux Libaraies
import { createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';

// Reducers
import MainReducer from "./reducer.main";

// Sagas
import MainSaga from './saga.main';

// Creates Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create store
const Store = createStore(MainReducer, applyMiddleware(sagaMiddleware));

// Runs Saga Middleware
sagaMiddleware.run(MainSaga);

export default Store
