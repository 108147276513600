import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Responsive, WidthProvider } from 'react-grid-layout';

import LayoutCard from '../layout_card/LayoutCard';
import BarChart from '../widgets/bar_chart/Bar';
import MetricCard from '../widgets/metric_card/MetricCard';
import DonutChart from '../widgets/donut_chart/DonutChart';

import { DEFAULT_QUEUE, CARD_BACKGROUND_COLOR, CARD_TEXT_COLOR } from '../../../../config'

const colors = {
	RED: '#FF0000',
	ORANGE: '#ffa500',
	GREEN: '#66CD00'
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const generateLayouts = () => {
	const twoRowFourColumn = [
		{ i: 'a', x: 0, y: 0, w: 1, h: 1 },
		{ i: 'b', x: 1, y: 0, w: 1, h: 1 },
		{ i: 'c', x: 2, y: 0, w: 1, h: 1 },
		{ i: 'd', x: 3, y: 0, w: 1, h: 1 },
		{ i: 'e', x: 0, y: 1, w: 1, h: 1 },
		{ i: 'f', x: 1, y: 1, w: 1, h: 1 },
		{ i: 'g', x: 2, y: 1, w: 1, h: 1 },
		{ i: 'h', x: 3, y: 1, w: 1, h: 1 }
	];
	const fourRowTwoColumn = [
		{ i: 'a', x: 0, y: 0, w: 1, h: 1 },
		{ i: 'b', x: 1, y: 0, w: 1, h: 1 },
		{ i: 'c', x: 0, y: 1, w: 1, h: 1 },
		{ i: 'd', x: 1, y: 1, w: 1, h: 1 },
		{ i: 'e', x: 0, y: 2, w: 1, h: 1 },
		{ i: 'f', x: 1, y: 2, w: 1, h: 1 },
		{ i: 'g', x: 0, y: 3, w: 1, h: 1 },
		{ i: 'h', x: 1, y: 3, w: 1, h: 1 }
	];
	const EightRowOneColumn = [
		{ i: 'a', x: 0, y: 0, w: 1, h: 1 },
		{ i: 'b', x: 0, y: 1, w: 1, h: 1 },
		{ i: 'c', x: 0, y: 2, w: 1, h: 1 },
		{ i: 'd', x: 0, y: 3, w: 1, h: 1 },
		{ i: 'e', x: 0, y: 4, w: 1, h: 1 },
		{ i: 'f', x: 0, y: 5, w: 1, h: 1 },
		{ i: 'g', x: 0, y: 6, w: 1, h: 1 },
		{ i: 'h', x: 0, y: 7, w: 1, h: 1 }
	];
	return {
		lg: twoRowFourColumn,
		md: fourRowTwoColumn,
		sm: EightRowOneColumn
	};
};

const LiveBoard = props => {

	let currentData = {};
	let historicData = {};

	if (props.selectedQueues.length > 0) {
		currentData.queues = props.currentData.queues.filter(queue => props.selectedQueues.includes(queue.name))
		historicData.queues = props.historicData.queues.filter(queue => props.selectedQueues.includes(queue.name))
	}
	else {
		currentData.queues = []
		currentData.queues.push(props.currentData[DEFAULT_QUEUE])
		historicData.queues = []
		historicData.queues.push(props.historicData[DEFAULT_QUEUE])
	}

	let layouts = generateLayouts();

	const chartsResponsiveContainerLayout = {
		width: '90%',
		height: '80%'
	};

	const getLongestCallWaiting = () => {
		let longest = 0
		for (const queue of currentData.queues) {
			if (queue.oldest_contact_age > longest) {
				longest = queue.oldest_contact_age
			}
		}
		const minutes = Math.floor(longest / 60000);
		const seconds = ((longest % 60000) / 1000).toFixed(0);
		return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
	}

	const getAverageHandleTime = () => {
		let averageHandleTime = 0
		let count = 0
		for (const queue of historicData.queues) {
			const handleTime = queue.handle_time
			if (handleTime > 0) {
				averageHandleTime += handleTime
				count += 1
			}
		}
		averageHandleTime = count > 0 ? averageHandleTime / count : 0
		const minutes = Math.floor(averageHandleTime / 60);
		const seconds = ((averageHandleTime % 60) / 1).toFixed(0);
		return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
	}

	const getTotalContactsInQueues = () => {
		let contacts = 0
		for (const queue of currentData.queues) {
			contacts += queue.contacts_in_queue
		}
		return contacts
	}

	const getTotalMissedCalls = () => {
		let missed = 0
		for (const queue of historicData.queues) {
			missed += queue.contacts_missed
		}
		return missed
	}

	const getAgentsOnline = () => {
		let agents = 0
		for (const queue of currentData.queues) {
			agents += queue.agents_online
		}
		return agents
	}

	const getAgentsOnCall = () => {
		let agents = 0
		for (const queue of currentData.queues) {
			agents += queue.agents_on_call
		}
		return agents
	}

	const getAgentsNonProductive = () => {
		let agents = 0
		for (const queue of currentData.queues) {
			agents += queue.agents_non_productive
		}
		return agents
	}

	const getAgentsAvailable = () => {
		let agents = 0
		for (const queue of currentData.queues) {
			agents += queue.agents_available
		}
		return agents
	}

	const getAgentStateDonut = () => {
		const agentsOnCall = getAgentsOnCall()
		const agentsNonProductive = getAgentsNonProductive()
		const agentsAvailable = getAgentsAvailable()

		if (agentsAvailable > 0) {
			return {
				data: [
					{
						name: 'Available', value: agentsAvailable
					},
					{
						name: 'Not available', value: agentsNonProductive
					},
					{
						name: 'Talking', value: agentsOnCall
					}
				],
				colors: [colors.GREEN, colors.RED, colors.ORANGE]
			}
		}
		return {
			data: [{ name: 'No agents available', value: 100 }],
			colors: ['grey'],
			renderLabel: false
		}
	}

	const getAgentStateBar = () => {
		const data = currentData.queues.map(entry => (
			{
				name: entry.name,
				'Available': entry.agents_available,
				'Not available': entry.agents_non_productive,
				'Talking': entry.agents_on_call

			}
		));

		const keys = [
			{ dataKey: 'Available', fill: colors.GREEN },
			{ dataKey: 'Not available', fill: colors.RED },
			{ dataKey: 'Talking', fill: colors.ORANGE }
		];

		return {
			data: data,
			keys: keys
		}
	}

	const getServiceLevel = () => {
		let service_level = 0
		for (const queue of historicData.queues) {
			service_level += queue.service_level
		}
		service_level = service_level / historicData.queues.length
		return +service_level.toFixed(2)
	}

	const getContactsQueued = () => {
		let queued = 0
		for (const queue of historicData.queues) {
			queued += queue.contacts_queued
		}
		return queued
	}

	const getContactsAbandoned = () => {
		let abandoned = 0
		for (const queue of historicData.queues) {
			abandoned += queue.contacts_abandoned
		}
		return abandoned
	}

	const getAbandonRate = () => {
		const contactsAbandoned = getContactsAbandoned()
		const contactsQueued = getContactsQueued()

		if (contactsQueued === 0) return 0
		else {
			const rate = (contactsAbandoned / contactsQueued) * 100
			return +rate.toFixed(2)
		}
	}

	const callsInQueues = currentData.queues.map(entry => ({
		name: entry.name,
		'Calls in queue': entry.contacts_in_queue
	}));

	const callsInQueuesBarKeys = [
		{ dataKey: 'Calls in queue', fill: colors.ORANGE }
	];

	return (
		<Grid container alignItems="center">
			<Grid item xs={12}>
				<ResponsiveGridLayout
					className="layout"
					layouts={layouts}
					breakpoints={{ lg: 1279, md: 800, sm: 0 }}
					cols={{ lg: 4, md: 2, sm: 1 }}
					rowHeight={400}
					isDraggable={props.isDraggable}
					isResizable={props.isResizable}
				>
					<Grid item key="a">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="AHT"
								value={getAverageHandleTime()}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="b">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="Oldest"
								value={getLongestCallWaiting()}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="c">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="Missed"
								value={getTotalMissedCalls()}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="d">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="Waiting"
								value={getTotalContactsInQueues()}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="e">
						<LayoutCard title={'Agent state'}>
							{currentData.queues.length > 1 ?
								<BarChart
									data={getAgentStateBar().data}
									barKeys={getAgentStateBar().keys}
									layout="vertical"
									responsiveContainerProps={chartsResponsiveContainerLayout}
								/>
								:
								<DonutChart
									data={getAgentStateDonut().data}
									COLORS={getAgentStateDonut().colors}
									midLabel={'Online:'}
									midValue={getAgentsOnline()}
									renderLabel={getAgentStateDonut().renderLabel}
									responsiveContainerProps={chartsResponsiveContainerLayout}
								/>
							}
						</LayoutCard>
					</Grid>
					<Grid item key="f">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="Service Level"
								value={`${getServiceLevel()}%`}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="g">
						<LayoutCard>
							<MetricCard
								background={CARD_BACKGROUND_COLOR}
								text={CARD_TEXT_COLOR}
								title="Abandon Rate"
								value={`${getAbandonRate()}%`}
							/>
						</LayoutCard>
					</Grid>
					<Grid item key="h">
						<LayoutCard title={'Calls in queues'}>
							<BarChart
								data={callsInQueues}
								barKeys={callsInQueuesBarKeys}
								layout="vertical"
								responsiveContainerProps={chartsResponsiveContainerLayout}
							/>
						</LayoutCard>
					</Grid>
				</ResponsiveGridLayout>
			</Grid>
		</Grid>
	);
};
export default LiveBoard;
