import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

//amplify config
import { AMPLIFY_CONFIG } from './config';

//theme
import Theme from './theme';

// Redux store
import Store from './redux/store';

const Persistor = persistStore(Store)

Amplify.configure(AMPLIFY_CONFIG);
Amplify.Logger.LOG_LEVEL = 'INFO';

ReactDOM.render(
    <Provider store={Store}>
        <PersistGate loading={null} persistor={Persistor} >
            <ThemeProvider theme={Theme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
