import DashboardReducer from './reducer'
import * as DashboardActions from './actions'
import * as DashboardTypes from './types'
import * as DashboardSagas from './sagas'
import * as DashboardOperations from './operations'

export {
    DashboardActions,
    DashboardTypes,
    DashboardSagas,
    DashboardOperations,
}

export default DashboardReducer